@import url("assets/fonts/_fonts.css");
@import '../node_modules/react-big-calendar/lib/css/react-big-calendar.css';

body {
  margin: 0;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
dl,
dd,
ol,
ul,
form,
fieldset,
legend,
input,
button,
textarea,
p {
  font-family: 'SamsungOne' !important;
}

*,
::after,
::before {
  box-sizing: border-box;
}

svg {
  pointer-events: none !important;
  flex-shrink: 0;
}

a {
  text-decoration: none;
}

img,
img:focus {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

*:not(.show-focus-outlines) *:focus,
*:not(.show-focus-outlines) [tabindex]:focus {
  outline: none;
}


.rbc-header {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  margin: 0;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 14 !important;
  line-height: 17px !important;
  align-items: center;

  justify-content: center;
  text-align: center;
  
  padding: 0;
  border-bottom: transparent;
  color: #888888;

}

.rbc-day-bg {
  border-right: 1px solid #DDD;
  border-left: none;
}

.rbc-day-bg + .rbc-day-bg {
  border-right: 1px solid #DDD;
  border-left: none;
}

.rbc-off-range-bg {
  background: transparent;
  border-left: none;
}

.rbc-off-range {
  font-size: 10px;
  line-height: 12px;
  color: #888888 !important;
}




.rbc-event {
  height: 14px;
  padding: 0;
}

.rbc-month-view {
  height: 'auto';
  /* max-height: 536px; */
  flex: none;
  border: none;
  z-index: 2;
}

.rbc-month-view .rbc-month-row:nth-child(2) .rbc-row-bg {
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}

.rbc-month-view .rbc-month-row:last-child  .rbc-row-bg {
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
}




.rbc-row .rbc-date-cell:nth-child(1) {
  color: #EE2B2A !important;
}

.rbc-month-header .rbc-header:nth-child(1) {
  color: #EE2B2A;

}

.rbc-month-row {
  /* height: 100px; */
  flex: 0 0 100px;
  overflow: visible;
}


.rbc-month-header {
  height: 28px;
}

.rbc-header + .rbc-header {
  border-left: none;
}

.rbc-day-bg + .rbc-day-bg {
  border-right: 2px solid #FFFFFF;  
}

.rbc-month-row + .rbc-month-row {
  border-top: 2px solid #FFFFFF;
}




.rbc-event-content {
  height: 100%;
  width: 100%;
  border-radius: 10px;
  font-size: 9px;
  line-height: 11px;
  align-items: center;
  padding: 1px 5px;
}

.rbc-day-bg {
  background: #F4F4F4;
  border-right: 2px solid #FFFFFF;
}

.rbc-date-cell {
  display: flex;
  justify-content: center;
  font-size: 10px;
  line-height: 12px;
  color: #444444;
  height: 20px;
  align-items: center;
  margin-bottom: 5px;
}

.rbc-date-cell a {
  cursor: default;
}

.rbc-now.rbc-current {
  background-color: #1EAD98;
  color: #FFFFFF;
  border-radius: 20px;
}

.rbc-show-more {
  font-size: 9px;
  line-height: 11px;
  margin: 0 5px;
}

/* .rbc-event::after,
.rbc-event::before {
  content: " ";
  border: 9px solid #19B9A9 !important;
  position: absolute;

}

.rbc-event::after  {
  border-right-color: transparent !important;
  right: 0;
}

.rbc-event::before  {
  border-left-color: transparent !important;
  left: 0;
} */