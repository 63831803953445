/* SamsungOne regular */
@font-face {
    font-family: 'SamsungOne';
    font-style: normal;
    font-weight: normal;
    src: url(SamsungOne/SamsungOne-400_v1.1_20161104.eot) format("eot"), url(SamsungOne/SamsungOne-400_v1.1_20161104.woff) format("woff")
}

/* Other FontWeights */

/* SamsungOne */


@font-face {
    font-family: 'SamsungOne';
    font-style: normal;
    font-weight: 300;
    src: url(SamsungOne/SamsungOne-400_v1.1_20161104.eot) format("eot"), url(SamsungOne/SamsungOne-400_v1.1_20161104.woff) format("woff")
}

@font-face {
    font-family: 'SamsungOne';
    font-style: normal;
    font-weight: 400;
    src: url(SamsungOne/SamsungOne-400_v1.1_20161104.eot) format("eot"), url(SamsungOne/SamsungOne-400_v1.1_20161104.woff) format("woff")
}

@font-face {
    font-family: 'SamsungOne';
    font-style: normal;
    font-weight: 500;
    src: url(SamsungOne/SamsungOne-400_v1.1_20161104.eot) format("eot"), url(SamsungOne/SamsungOne-400_v1.1_20161104.woff) format("woff")
}

@font-face {
    font-family: 'SamsungOne';
    font-style: normal;
    font-weight: 600;
    src: url(SamsungOne/SamsungOne-400_v1.1_20161104.eot) format("eot"), url(SamsungOne/SamsungOne-400_v1.1_20161104.woff) format("woff")
}

@font-face {
    font-family: 'SamsungOne';
    font-style: normal;
    font-weight: bold;
    src: url(SamsungOne/SamsungOne-700_v1.1_20161104.eot) format("eot"), url(SamsungOne/SamsungOne-700_v1.1_20161104.woff) format("woff")
}

@font-face {
    font-family: 'SamsungOne';
    font-style: normal;
    font-weight: 700;
    src: url(SamsungOne/SamsungOne-700_v1.1_20161104.eot) format("eot"), url(SamsungOne/SamsungOne-700_v1.1_20161104.woff) format("woff")
}

@font-face {
    font-family: 'SamsungOne';
    font-style: normal;
    font-weight: 800;
    src: url(SamsungOne/SamsungOne-700_v1.1_20161104.eot) format("eot"), url(SamsungOne/SamsungOne-700_v1.1_20161104.woff) format("woff")
}
